import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActiveFormSection } from 'utils/hooks/useActiveFormSection';
import InvolvementsViewMode from './viewMode';
import InvolvementsEditMode from './editMode';
import ProfileSubsectionLayout from '../../layout/ProfileSubsectionLayout';

const Involvements = () => {
  const aInvolvements = useSelector((state) => state.Profile.INVOLVEMENT);
  const [activeFormSection] = useActiveFormSection();
  const [bIsEditMode, fnSetIsEditMode] = useState(false);
  useEffect(() => {
    if (activeFormSection === 'education') {
      fnSetIsEditMode(true);
    } else {
      fnSetIsEditMode(false);
    }
  }, [activeFormSection]);

  return (
    <ProfileSubsectionLayout
      sTitle='Involvements'
      right={
        bIsEditMode ? (
          <InvolvementsEditMode aInvolvements={aInvolvements} />
        ) : (
          <InvolvementsViewMode aInvolvements={aInvolvements} />
        )
      }
    />
  );
};

export default Involvements;
