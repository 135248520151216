import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays list of involvements.
 */
const InvolvementsViewMode = ({ aInvolvements }) =>
  aInvolvements.length === 0 ? (
    <p className='record t-paragraph--medium'>None</p>
  ) : (
    <ul className='involvements__list'>
      {aInvolvements.map((oInvolvement, iIndex) => {
        let sSeparator = '';
        // When there's more than 1 involvement, add a comma and a space between skills
        if (aInvolvements.length > 1 && iIndex !== aInvolvements.length - 1) {
          sSeparator = ', ';
        }
        return (
          <li className='record t-paragraph--medium' key={oInvolvement.ID}>
            {oInvolvement.NAME}
            {sSeparator}
          </li>
        );
      })}
    </ul>
  );

InvolvementsViewMode.propTypes = {
  aInvolvements: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.string,
      NAME: PropTypes.string,
    })
  ),
};

InvolvementsViewMode.defaultProps = {
  aInvolvements: [],
};

export default InvolvementsViewMode;
