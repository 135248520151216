import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Button from 'components/Buttons/Button';
import LoadingZone from 'components/LoadingZone';
import oWnApi from 'utils/WnApi';
import Modal from 'components/Layout/Modal/Modal';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { useSelector } from 'react-redux';
import {
  currentUserLookupIdSelector,
  selectCurrentUserEmail,
} from 'redux/selectors';

const ConfirmCancelRegistrationModal = ({
  oEvent,
  fnSetShowCancelRegistrationConfirmModal,
}) => {
  const { eventSlug } = useParams();
  const { ID, TITLE } = oEvent;
  const bHasCost = oEvent.REGISTRATION.HAS_COST === 1;
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const [bIsProcessingCancellation, fnSetIsProcessingCancellation] =
    useState(false);
  const [sCancellationPhase, fnSetCancellationPhase] = useState('confirm'); // ['confirm', 'success', 'error']
  const sCurrentUserEmail = useSelector(selectCurrentUserEmail);
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const fnCancelRegistration = async () => {
    fnSetIsProcessingCancellation(true);
    const oResponse = await oWnApi.post(
      `events/${eventSlug}/cancelregistration`,
      {
        USER_LOOKUPID: sCurrentUserLookupId,
        EVENT_ID_LIST: ID,
      },
      bIsUserAuthenticated
    );
    if (oResponse.status === 200) {
      fnSetCancellationPhase('success');
    } else {
      console.error('Failed to cancel registration for event');
      fnSetCancellationPhase('error');
    }
    fnSetIsProcessingCancellation(false);
  };

  const oModalMessages = {
    confirm:
      'Are you sure you want to cancel the event registration for the following event(s):',
    success: 'The event registration has been canceled.',
    error: 'Something went wrong. Please try again.',
  };

  const oModalDetail = {
    confirm: (
      <>
        <p>{TITLE}</p>
        <p className='t-paragraph--small'>
          Please note that this cancellation applies to both the primary and all
          guests.
          {bHasCost &&
            ' To request a refund, please refer to the Refund Policy on the event details page.'}
        </p>
      </>
    ),
    success: (
      <>
        A confirmation email has been sent to{' '}
        <strong>{sCurrentUserEmail}</strong>.
        <br />
        <br />
        We&apos;re sorry that you can&apos;t join us, but we hope to see you at
        a future event.
      </>
    ),
    error: '',
  };

  return (
    <Modal
      sFlavor={sCancellationPhase === 'success' ? 'message' : 'alert'}
      sModalSubtitle={oModalMessages[sCancellationPhase]}
      sModalTitle='Cancel Event Registration'
      sModalDetail={oModalDetail[sCancellationPhase]}
      fnSetShowModal={fnSetShowCancelRegistrationConfirmModal}
      sCypressId='cancel-registration-modal'
    >
      {sCancellationPhase === 'confirm' && (
        <LoadingZone isLoading={bIsProcessingCancellation}>
          <Button
            fnHandleClick={fnCancelRegistration}
            sCypressId='cancel-registration-confirm-button'
          >
            Confirm Cancellation
          </Button>
        </LoadingZone>
      )}
      {sCancellationPhase === 'success' && (
        <Button
          fnHandleClick={() => fnSetShowCancelRegistrationConfirmModal(false)}
          sFlavor='secondary'
          sCypressId='cancel-registration-success-close-button'
        >
          Continue
        </Button>
      )}
      {sCancellationPhase === 'error' && (
        <Button
          fnHandleClick={() => fnSetShowCancelRegistrationConfirmModal(false)}
          sFlavor='secondary'
        >
          Close
        </Button>
      )}
    </Modal>
  );
};

ConfirmCancelRegistrationModal.propTypes = {
  oEvent: PropTypes.shape({
    ID: PropTypes.number.isRequired,
    TITLE: PropTypes.string.isRequired,
    REGISTRATION: PropTypes.shape({
      HAS_COST: PropTypes.number,
    }),
  }).isRequired,
  fnSetShowCancelRegistrationConfirmModal: PropTypes.func.isRequired,
};

export default ConfirmCancelRegistrationModal;
